<template>
  <div>
    <section class="section section__inscription">
      <div class="container">
        <div class="breadcrumb">
          <div class="container">
            <ol class="items">
              <li class="item">
                <span>Mon compte</span>
              </li>
            </ol>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h1 class="h1-light mb-4">Mon compte</h1>
            <div class="account box-update">
              <h2 class="h2-primary mb-4">Pour toute demande</h2>
              <div>
                <ValidationObserver ref="observer">
                  <form autocomplete="off" method="">
                    <div class="row mt-3">
                      <validation-provider
                        name="requestObject"
                        rules="required"
                        v-slot="{ errors }"
                        class="col-lg-4 col-sm-12"
                        mode="eager"
                      >
                        <atom-select
                          v-model="requestObject"
                          :options="requestObjectOptions"
                          placeholder="Objet de votre demande"
                          label=""
                          type="text"
                          :select-class="{
                            'form-select': true,
                            'form-control-light': true,
                            'form-control-light-bordered': true,
                            'is-invalid': errors.length,
                          }"
                          label-class="label-dark label-lg"
                          @change="getUserORders"
                        />
                      </validation-provider>
                      <validation-provider
                        name="requestObject"
                        rules="required"
                        v-slot="{ errors }"
                        class="col-lg-4 col-sm-12"
                        mode="eager"
                        v-if="
                          requestObject == `Demande d'annulation` ||
                          requestObject == `Demande de modification`
                        "
                      >
                        <label for=""></label>
                        <select
                          v-model="training"
                          placeholder="Formation*"
                          :class="{
                            'form-select': true,
                            'form-control-light': true,
                            'form-control-light-bordered': true,
                            'is-invalid': errors.length,
                          }"
                          @change="getSession"
                        >
                          <option value="" selected>Votre Formation*</option>
                          <option
                            v-for="(training, index) in userTrainingsList"
                            :key="index"
                            :value="training"
                            >{{ training.courseTitle }}</option
                          >
                        </select>
                      </validation-provider>
                      <validation-provider
                        name="requestObject"
                        rules="required"
                        v-slot="{ errors }"
                        class="col-lg-4 col-sm-12"
                        mode="eager"
                        v-if="training"
                      >
                        <label for=""></label>
                        <select
                          v-model="session"
                          placeholder="Date session*"
                          :class="{
                            'form-select': true,
                            'form-control-light': true,
                            'form-control-light-bordered': true,
                            'is-invalid': errors.length,
                          }"
                        >
                          <option value="" selected>Date session*</option>
                          <option
                            v-for="(session, index) in sessionDates"
                            :key="index"
                            :value="session"
                            >{{
                              formatSessionDate(session.sessionStartDate)
                            }}</option
                          >
                        </select>
                      </validation-provider>
                    </div>
                    <div class="row mt-3">
                      <validation-provider
                        name="request"
                        rules="required"
                        v-slot="{ errors }"
                        class="col-lg-12 col-sm-12"
                        mode="eager"
                      >
                        <atom-textarea
                          v-model="request"
                          label=""
                          placeholder="Votre demande*"
                          :input-class="{
                            'form-control': true,
                            'form-control-light': true,
                            'fform-control-light-bordered': true,
                            'is-invalid': errors.length,
                            'h-100': true,
                            'p-3': true,
                          }"
                          label-class="d-none"
                          rows="10"
                        />
                      </validation-provider>
                      <div
                        class="col-lg-12 col-sm-12 mt-4 box-update__footer mb-5"
                      >
                        <div class="mb-2">
                          <p class="small text-muted text-decoration-underline">
                            *Attention ces informations ne seront prises en
                            compte qu’une fois validées par l’EDBN
                          </p>
                        </div>
                        <atom-button
                          @click="send"
                          btn-class="btn btn-primary "
                          type="button"
                          title="Envoyer votre demande"
                        />
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import AtomTextarea from '@/components/atoms/AtomTextarea'
import AtomSelect from '@/components/atoms/AtomSelect'
import AtomButton from '@/components/atoms/AtomButton'
import { mapFields } from 'vuex-map-fields'
const Swal = require('sweetalert2')
export default {
  name: 'UpdateRequest',
  components: {
    AtomTextarea,
    AtomSelect,
    AtomButton,
  },
  data() {
    return {
      request: '',
      requestObject: '',
      requestObjectOptions: [
        {
          id: "Demande d'annulation",
          name: "Demande d'annulation",
        },
        {
          id: 'Demande de modification',
          name: 'Demande de modification',
        },
        {
          id: 'Autre demande',
          name: 'Autre demande',
        },
      ],
      training: '',
      sessionDates: [],
      session: '',
      Toast: Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
      }),
    }
  },
  computed: {
    ...mapFields('app', ['user']),
    ...mapFields('trainings', ['userTrainings']),
    userTrainingsList() {
      return this.$lodash.uniqBy(this.userTrainings, 'courseTitle')
    },
  },
  watch: {
    requestObject(val) {
      if (val == `Demande de modification` || val == `Demande d'annulation`) {
        this.getUserORders()
      }
    },
  },
  mounted() {},
  methods: {
    formatSessionDate(date) {
      return this.$moment(date).format('LLL')
    },
    getSession() {
      this.sessionDates = this.$lodash.uniqBy(
        this.userTrainings.filter(
          (training) => training.courseTitle == this.training.courseTitle
        ),
        'sessionStartDate'
      )
      if (this.sessionDates.length == 1) {
        this.session = this.sessionDates[0]
      }
    },
    getUserORders() {
      let id = this.user.id
      this.$store.dispatch('trainings/getUserOrders', id)
    },
    send() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return
        }
        let data = {}
        const courseName = Cookies.get('courseName')
        data.action = 'update_account_request'
        data.request = this.request
        data.requestObject = this.requestObject
        data.firstname = this.user.firstname || ''
        data.lastname = this.user.lastname || ''
        data.email = this.user.email || ''
        data.courseName = courseName
        data.courseTitle = this.training.courseTitle
        data.courseRef = this.training.courseRef
        data.createdAt = this.$moment().format('LLL')
        data.location = this.session.location
        data.sessionId = this.session.sessionId
        data.sessionStartDate = this.$moment(
          this.session.sessionStartDate
        ).format('LLL')
        this.$store.dispatch('app/sendAccountUpdateRequest', data).then(() => {
          this.Toast.fire({
            icon: 'success',
            title: 'Votre demande a éte envoyer avec succées',
          }).then(() => {
            window.location = '/mon-compte'
          })
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped></style>
